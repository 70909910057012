import { useNavigate } from '@remix-run/react';
import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { $path } from 'remix-routes';

import {
  type DtoLearnerProfileResponse,
  type DtoLearningEnrollment,
  type DtoLearningEnrollmentsResponse,
} from '@lp-lib/api-service-client/public';

import { ErrorMessage } from '../../../../../app/components/ErrorMessage';
import placeholderCover from '../../../../assets/img/placeholder/game-cover.png';
import { useListLoader } from '../../../../hooks/useListLoader';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { getLogger } from '../../../../logger/logger';
import { apiService } from '../../../../services/api-service';
import { fromMediaDTO } from '../../../../utils/api-dto';
import {
  ImagePickPriorityLowToHigh,
  MediaUtils,
} from '../../../../utils/media';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { Loading } from '../../../Loading';
import { ActionsMenu } from '../Components/ActionMenu';
import { AssessmentScoreButton } from '../Components/AssessmentScoreButton';
import { PageHeader } from '../Components/PageHeader';

const logger = getLogger().scoped('learner-enrollments');

interface LearnerEnrollmentsPageProps {
  profile: DtoLearnerProfileResponse;
}

export function LearnerEnrollmentsPage({
  profile,
}: LearnerEnrollmentsPageProps) {
  return (
    <div className='flex flex-col w-full text-white max-w-screen-2xl mx-auto relative'>
      <div className='w-full px-4 md:px-8 pt-4 md:pt-8 pb-1'>
        <PageHeader
          title={profile.name || 'Profile'}
          path={$path('/learning/admin/learners/:id/profile', {
            id: profile.uid,
          })}
        />
      </div>
      <div className='pt-4 pb-10 space-y-20'>
        <EnrollmentsTable profile={profile} />
      </div>
    </div>
  );
}

function EnrollmentsTable({ profile }: LearnerEnrollmentsPageProps) {
  const paginator = useMemo(
    () => apiService.learning.getLearnerEnrollments(profile.uid),
    [profile.uid]
  );
  const { items, state, error, dao, handleLoadMore, handleRetry } =
    useListLoader<DtoLearningEnrollmentsResponse, DtoLearningEnrollment>(
      paginator,
      (a, b) => a.id === b.id
    );
  const handleUnassign = useLiveCallback(
    async (enrollment: DtoLearningEnrollment) => {
      try {
        if (enrollment.type === 'stack') {
          await apiService.learning.unassignMemberFromStack(
            enrollment.objectId,
            enrollment.uid
          );
        } else {
          await apiService.learning.unassignMemberFromCourse(
            enrollment.objectId,
            enrollment.uid
          );
        }
        dao.deleteItem(enrollment);
      } catch (error) {
        logger.error('failed to unenroll member ', error, {
          targetObjectId: enrollment.objectId,
          targetObjectType: enrollment.type,
          targetUserId: enrollment.uid,
        });
      }
    }
  );
  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  return (
    <div className='w-full px-4 md:px-8'>
      <div className='pb-6 flex items-center justify-between'>
        <div>
          <div className='font-bold text-2xl'>Course Assignments</div>
        </div>
      </div>

      <div className='w-full'>
        <div className='flex flex-col mt-4 text-gray-300 gap-2.5'>
          {items.map((e) => (
            <EnrollmentRowItem
              key={e.id}
              enrollment={e}
              onUnassign={() => handleUnassign(e)}
            />
          ))}
        </div>
      </div>

      <div className='w-full mt-8'>
        {state.isRunning && (
          <div className='flex items-center justify-center'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-icon-gray'>
            No enrollments.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
}

export function EnrollmentRowItem(props: {
  enrollment: DtoLearningEnrollment;
  onUnassign: () => void;
  learnerName?: string;
}) {
  const { enrollment, onUnassign, learnerName } = props;
  const navigate = useNavigate();

  const handleClick = useLiveCallback(() => {
    if (enrollment.type === 'stack') {
      navigate(
        $path('/learning/admin/my-courses/stacks/:id', {
          id: enrollment.objectId,
        })
      );
    } else {
      navigate(
        $path('/learning/admin/my-courses/:id', {
          id: enrollment.objectId,
        })
      );
    }
  });

  const actionSheet = useMemo(() => {
    return (
      <ActionsMenu
        actions={[
          {
            label: 'Unassign Member',
            icon: <DeleteIcon className='w-6 h-6 fill-current pr-2' />,
            onClick: onUnassign,
            variant: 'danger',
          },
        ]}
        className='w-50'
      />
    );
  }, [onUnassign]);

  const coverSrc =
    MediaUtils.PickMediaUrl(fromMediaDTO(enrollment.cover), {
      priority: ImagePickPriorityLowToHigh,
    }) ?? placeholderCover;

  return (
    <div
      className={`
        relative w-full px-4 py-3
        bg-[#17171A] hover:bg-[#1f1f22] transition-colors 
        flex items-center
        border border-lp-gray-003 rounded-lg
        cursor-pointer
      `}
      onClick={handleClick}
    >
      <div className='flex-1 flex items-center gap-4'>
        <div className='w-21 h-12 rounded-lg bg-gray-700 overflow-hidden'>
          <img
            src={coverSrc}
            alt='Enrollment Cover'
            className='w-full h-full object-cover'
          />
        </div>
        <div className='flex flex-col'>
          {enrollment.type === 'stack' && (
            <div className='text-luna-secondary uppercase text-3xs'>
              Course Stack
            </div>
          )}
          <div className='flex-1 text-white font-bold text-sm truncate'>
            {enrollment.displayName}
          </div>
        </div>
      </div>
      <div className='w-24 text-white/90 text-sm text-center'>
        {enrollment.completedAt ? '100%' : `${enrollment.progressionPct}%`}
      </div>
      <div className='w-24 text-center'>
        <AssessmentScoreButton
          progression={enrollment.progression ?? undefined}
          learnerName={learnerName}
        />
      </div>
      <div className='w-12 flex justify-end'>{actionSheet}</div>
    </div>
  );
}
